import { WordType } from "./types";

export const dictionary: Record<WordType, string[]> = {
  spacer: [""],
  noun: [
    "man",
    "woman",
    "child",
    "technology",
    "vehicle",
    "structure",
    "film",
    "container",
    "opposite",
    "material",
    "name",
    "medicine",
    "mouth",
    "head",
    "event",
    "love",
    "music",
    "object",
    "process",
    "place",
    "day",
    "night",
    "feeling",
    "illness",
    "north",
    "south",
    "west",
    "east",
    "animal",
    "plant",
    "science",
    "religion",
    "earth",
    "air",
    "website",
    "social media",
    "body",
    "economy",
    "picture",
    "conflict",
    "sight",
    "journey",
    "city",
    "country",
    "politics",
    "metal",
    "wood",
    "sky",
    "job",
    "sport",
    "time",
    "circle",
    "literature",
    "weather",
  ],
  verb: [
    "eat",
    "drink",
    "give",
    "take",
    "turn",
    "fly",
    "run",
    "sit",
    "play",
    "make",
    "read",
    "write",
    "jump",
    "smell",
    "see",
    "speak",
    "hear",
    "shoot",
  ],
  preposition: [
    "many",
    "for",
    "under",
    "over",
    "out",
    "on",
    "by",
    "in",
    "not",
    "above",
    "below",
    "left",
    "right",
    "zero",
    "one",
    "two",
    "times",
    "with",
  ],
  adjective: [
    "white",
    "black",
    "green",
    "red",
    "blue",
    "orange",
    "yellow",
    "brown",
    "grey",
    "transparent",
    "long",
    "thin",
    "loud",
    "funny",
    "fictitious",
    "sweet",
    "divided",
    "historical",
    "dead",
    "hot",
    "cold",
    "bad",
    "good",
    "big",
    "small",
    "old",
    "new",
    "strong",
    "weak",
    "beautiful",
    "bright",
    "soft",
    "heavy",
    "fast",
  ],
};

export function randStory() {
  const all = Object.values(stories).flat();
  return all[Math.floor(Math.random() * all.length)];
}
export const stories = {
  "Animals, Plants, Creatures": [
    "Mammoth",
    "Wolf",
    "Tick",
    "Bear",
    "Goblin",
    "Fairy",
    "Deer",
    "Thimble",
    "Dwarf",
    "Antelope",
    "Clover",
    "Tiger",
    "Panther",
    "Unicorn",
    "Basil",
    "Sloth",
    "Dandelion",
    "Meerkat",
    "Moss",
    "Anise",
    "Polar bear",
    "Monkey",
    "Beehive",
    "Cyclops",
    "Duck",
    "Orangutan",
    "Insect",
    "Garden gnome",
    "Poppy",
    "Amoeba",
    "Meow",
    "Parakeet",
    "Violet",
    "Centaur",
    "Lynx",
    "Birch",
    "Tadpole",
    "Pony",
    "Chestnut",
    "Phoenix",
    "Parsley",
    "Pig",
    "Ghost",
    "Minotaur",
    "Beaver",
    "Mango",
    "Carnivorous plant",
    "Easter Bunny",
    "Siren",
    "Caterpillar",
    "Snake",
    "Calf",
    "Serpent",
    "Skunk",
    "Griffin",
    "Algae",
    "Pegasus",
    "Raccoon",
    "German shepherd",
    "Chimpanzee",
    "Seal",
    "Mummy",
    "Elf",
    "Medusa",
    "Reptile",
    "Salamander",
    "Lion",
    "Gremlin",
    "Grasshopper",
    "Ladybug",
    "Angel",
    "T-Rex",
    "Woof",
    "Daffodil",
    "Dog",
    "Bumblebee",
    "Shell",
    "Monster",
    "Lemming",
    "Fish",
    "Giant",
    "Mouse",
    "Pelican",
    "Husky",
  ],
  "Travel, Outdoors, History": [
    "Tour de France",
    "Amsterdam",
    "Buckingham Palace",
    "Barcelona",
    "Oasis",
    "Washington",
  ],
  "Food & Drink": [
    "Wasabi",
    "Caviar",
    "Energy drink",
    "Pancake",
    "Dessert",
    "Radish",
    "Herring",
    "Chocolate truffle",
    "Pickles",
    "Apple pie",
    "Organic",
    "Dumpling",
    "Ham",
    "Meatball",
    "Snapple",
    "Wooden spoon",
    "Olive",
    "Pea",
    "Raspberry",
    "Cup",
    "Salami",
    "Swiss cheese",
    "Grain",
    "Ground beef",
    "Filet",
    "Hunger",
    "Gingerbread",
    "Seed",
    "Birthday cake",
    "Breadcrumbs",
    "Sushi",
    "Sauce",
    "Easter egg",
    "Herbs",
    "Onion",
    "Brunch",
    "Kebab",
    "Antipasti",
    "Paella",
    "Cook",
    "Bacon",
    "Balsamic",
    "Tap water",
    "Loaf",
    "Seltzer",
    "BBQ",
    "Nutella",
    "Smoothie",
    "Sugar",
    "Ranch",
    "Goulash",
    "Thirst",
    "Sausage",
    "Toast",
    "Garlic",
    "Doughnut",
    "Oatmeal",
    "Menu",
    "Cappucino",
    "Taco",
    "Hamburger",
    "Cracker",
    "Orange juice",
    "Harvest",
    "Lasagna",
    "Vegetable",
    "Lemonade",
    "Hot dog",
    "Oil",
    "Salmon",
    "Drinking straw",
    "Croissant",
    "Cutlery",
    "Fat",
    "Appetizer",
    "Iced tea",
    "Feed",
    "Cornucopia",
    "Stew",
    "Cheese",
    "Tuna",
    "Fruit salad",
    "Pretzel",
  ],
  "Web & Social Media": [
    "Playstation",
    "E-commerce",
    "Post",
    "ROFL",
    "Password",
    "Snapchat",
    "Pepe",
    "Pinterest",
    "Alexa",
    "App store",
    "Wireless",
    "Photoshop",
    "Cursor",
    "Playlist",
    "Instagram",
    "Clicks",
    "Nerd",
    "Duck face",
    "YOLO",
    "Candy Crush",
    "#nofilter",
    "Pixel",
    "Blog",
    "Livestream",
    "Youtuber",
    "Like",
    "iOS",
    "App",
    "Google",
    "MP3",
    "GIF",
    "Windows",
    "TripAdviser",
    "Profile",
    "Bluetooth",
    "Download",
    "Emoji",
    "Skylander",
    "Netflic",
    "Newsletter",
    "Audio message",
    "Virtual reality",
    "iTunes",
    "Binary code",
    "Flash drive",
    "Hashtag",
    "Megabyte",
    "User",
    "Homepage",
    "Internet celebrity",
    "Follower",
    "Hacker",
    "RAM",
    "Firefox",
    "WhatsApp",
    "Charger",
    "OMG",
    "Android",
    "Messenger",
    "Cloud",
    "Selfie",
    "QR Code",
    "Internet troll",
    "Shazam",
    "Spam",
    "Hotspot",
    "Streaming",
    "Minecraft",
    "Graphics card",
    "Pokemon",
    "Hater",
    "Display",
    "Apple",
    "Influencer",
    "Update",
    "Podcast",
    "Twitter",
    "Facebook",
    "Twitch",
    "Cat videos",
    "LOL",
    "Screenshot",
    "Touchscreen",
    "Nintendo",
    "Meme",
  ],
  "Emotions & Miscellaneous": [
    "Paw",
    "Rumor",
    "Driving instructor",
    "Feather",
    "Hot-water bottle",
    "Compass",
    "Punk",
    "Piggy bank",
    "Pressure cooker",
    "Joy",
    "Invitation",
    "Humankind",
    "Nausea",
    "Happiness",
    "Dog leash",
    "Mockery",
    "Credit card",
    "Shelf",
    "Holiday",
    "Bedroom",
    "Word",
    "Blood donation",
    "Voice",
    "Province",
    "Shoe size",
    "Coin",
    "Thief",
    "Pipe break",
    "Endurance",
    "Medicine ball",
    "Grandson",
    "Index finger",
    "Patient",
    "Waiting room",
    "Artist",
    "Spending money",
    "Leather",
    "Moment",
    "Silence",
    "Sneeze",
    "Tweak",
    "Cellar",
    "Can",
    "Aspirin",
    "Red Cross",
    "Silver",
    "Drawer",
    "Reward",
    "Absence",
    "Nomad",
    "Disgust",
    "Fountain",
    "Testimony",
    "Chain",
    "Foreigner",
    "Windowsill",
    "Grade",
    "Ladder",
    "Month",
    "Dollar",
  ],
  "Science, Technology, Religion": [
    "Black hole",
    "Brain",
    "Devil",
    "Rust",
    "Logbook",
    "Communion",
    "Buddhism",
    "Prayer",
    "God",
    "Eternity",
    "Rabbi",
    "Time machine",
    "Moses",
    "Memory",
    "Battery pack",
    "Koran",
    "Garden of Eden",
    "Mosque",
    "Allah",
    "Judaism",
    "Enlightenment",
    "Past",
    "Ambulance",
    "Snowplow",
    "GPS",
    "Comet",
    "Zeus",
    "Jesus",
    "Bible",
    "Record player",
    "Minute hand",
    "Schedule",
    "Choir",
    "Aphrodite",
    "Islam",
    "Nuclear power",
    "Collection plate",
    "Pluto",
    "Physics",
    "Adam",
    "Inventor",
    "Tractor",
    "Immortality",
    "Paradise",
    "Clock",
    "Test tube",
    "Church",
    "Bishop",
    "Photosynthesis",
    "Kickstand",
    "Ark",
    "Lunar eclipse",
    "Temple",
    "Research",
    "Confucious",
    "Drone",
    "Pope",
    "Confession",
    "Space station",
    "Eve",
    "Segway",
    "Alphabet",
    "Laboratory",
    "X-ray",
    "Christianity",
    "Notebook",
    "Old Testament",
    "Weightlessness",
    "Hell",
    "Speed",
    "Ten Commandments",
    "Artifact",
    "Astrology",
    "Acid",
    "Air conditioner",
    "Angel",
    "Nocturnal",
    "Chemistry",
    "Noah",
    "Future",
    "Gravitation",
    "Spaceship",
    "Recycling",
    "Biology",
    "Wind speed",
    "Astronomy",
    "Milky Way",
    "Radar",
    "Flow",
    "Hypothesis",
  ],
  "Movies, Music, Literature": [
    "Photograph",
    "Karl Lagerfeld",
    "The Hobbit",
    "LeBron James",
    "Mark Twain",
    "Step Up",
    "The Love Boat",
    "Star Trek",
    "World of Warcraft",
    "The Big Bang Theory",
    "X-Men",
    "News",
    "The Cat in the Hat",
    "South Park",
    "Barack Obama",
    "Beauty and the Beast",
    "Calvin and Hobbes",
    "Pink",
    "People Magazine",
    "Modern Family",
    "Hulk",
    "Downton Abbey",
    "Baywatch",
    "Heavy Metal",
    "Sesame Street",
    "Toy Story",
    "Alice in Wonderland",
    "The Phantom of the Opera",
    "Law & Order",
    "The Fast and the Furious",
    "Red carpet",
    "Candid Camera",
    "Julia Roberts",
    "Inside Out",
    "Magneto",
    "Planet of the Apes",
  ],
};

import { motion } from "framer-motion";
import { useMemo } from "react";
import { shuffle } from "../common/utils";

const blobs = [
  "M59.6,-34.1C73.7,-10.2,78.9,19.2,67.5,42.1C56.1,65.1,28,81.5,0.9,81C-26.2,80.5,-52.3,62.9,-65.7,38.8C-79.1,14.7,-79.8,-15.9,-66.7,-39.3C-53.6,-62.7,-26.8,-79,-2,-77.8C22.8,-76.7,45.6,-58.1,59.6,-34.1Z",
  "M65.6,-34.4C80,-12.9,83.2,18.5,70.4,39.3C57.6,60.1,28.8,70.3,0.2,70.2C-28.4,70.1,-56.7,59.6,-69.2,39C-81.7,18.3,-78.4,-12.5,-64.2,-33.9C-50,-55.3,-25,-67.3,0.3,-67.5C25.6,-67.6,51.2,-56,65.6,-34.4Z",
  "M58.6,-34.9C71.3,-12,73.5,15.8,61.9,37.7C50.4,59.5,25.2,75.4,0.3,75.2C-24.7,75.1,-49.4,58.9,-63.2,35.8C-76.9,12.6,-79.7,-17.5,-67.4,-40.2C-55,-63,-27.5,-78.3,-2.2,-77C23,-75.7,46,-57.8,58.6,-34.9Z",
  "M61.1,-34.9C73.6,-13.6,74.3,14.9,62.1,35.6C50,56.4,25,69.6,0.4,69.3C-24.2,69.1,-48.3,55.5,-62.6,33.4C-76.9,11.4,-81.3,-19,-69.2,-40.1C-57.2,-61.1,-28.6,-72.6,-2.1,-71.4C24.3,-70.1,48.6,-56.1,61.1,-34.9Z",
];
export function Blob({
  text,
  cluemaster = false,
  isYou = false,
}: {
  text: string;
  cluemaster?: boolean;
  isYou: boolean;
}) {
  const randBlobs = useMemo(() => shuffle(blobs), []);
  const duration = useMemo(() => Math.random() + 1.5 * blobs.length, []);
  const delay = useMemo(() => Math.random(), []);
  return (
    <div className="relative flex items-center justify-center">
      <div className="text-white absolute">{text}</div>
      <svg
        width="180"
        height="130"
        viewBox="-90 -90 180 180"
        preserveAspectRatio="none"
        className="fill-current transition-colors"
        xmlns="http://www.w3.org/2000/svg"
      >
        <motion.path
          animate={{ d: [...randBlobs, randBlobs[0]] }}
          stroke="#000"
          strokeWidth={isYou ? 5 : 0}
          transition={{
            delay,
            duration,
            stiffness: 200,
            loop: Infinity,
          }}
        />
        {cluemaster && (
          <g
            transform="translate(-15 -60)
                     scale(0.12 0.12)"
            fill="#F2BC78"
          >
            <path d="M256.975 100.34a7.48 7.48 0 01-.198 2.229l-16.5 66a7.503 7.503 0 01-7.238 5.681l-99 .5H35a7.5 7.5 0 01-7.277-5.688l-16.5-66.25a7.488 7.488 0 01-.197-2.289C4.643 98.512 0 92.539 0 85.5c0-8.685 7.065-15.75 15.75-15.75S31.5 76.815 31.5 85.5c0 4.891-2.241 9.267-5.75 12.158l20.658 20.814c5.221 5.261 12.466 8.277 19.878 8.277 8.764 0 17.12-4.162 22.382-11.135l33.95-44.984A15.694 15.694 0 01118 59.5c0-8.685 7.065-15.75 15.75-15.75s15.75 7.065 15.75 15.75c0 4.212-1.672 8.035-4.375 10.864.009.012.02.022.029.035l33.704 45.108c5.26 7.04 13.646 11.243 22.435 11.243 7.48 0 14.514-2.913 19.803-8.203l20.788-20.788C238.301 94.869 236 90.451 236 85.5c0-8.685 7.065-15.75 15.75-15.75s15.75 7.065 15.75 15.75c0 6.851-4.405 12.678-10.525 14.84zm-18.308 97.91a7.5 7.5 0 00-7.5-7.5h-194a7.5 7.5 0 00-7.5 7.5v18a7.5 7.5 0 007.5 7.5h194a7.5 7.5 0 007.5-7.5v-18z" />
          </g>
        )}
      </svg>
    </div>
  );
}
